import './App.css';

// v0.3

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header-left">
          <img src="./pfp.png" alt="Joan Aneas" width={"60px"}/>
          <h1>Joan Aneas</h1>
        </div>
        
        <div className="App-header-right">
          <a href="#projectsDiv">Projects</a>
          <a href="#skillsDiv">Skills</a>
          {/* <a href="#contactDiv">Contact</a>
          <button>Language</button> */}
        </div>
      </header>

      <div className="App-slider">
        <h2>Junior Web Developer</h2>
        <p>I'm Joan, a student who loves coding, gaming and having fun with friends. I'm on a DAM (Multiplatform App Development) degree.
          Software Engineering is my passion and I'm always looking for new challenges to improve my skills. I'm currently working on a few projects,
          such as a phishing website and a few games. I'm also learning new languages and frameworks, such as React (this was built with react hehe) and Node.js. I'm also a big fan of C++ and PHP.
          This is my personal website, where I'll be posting my projects and my progress. I hope you enjoy it!
        </p>
      </div>
      
      <div className="light App-frame-projects" id="projectsDiv">
        <h2>Projects</h2>
        <Project title={"Salmon🍣"} description={"Email and password grabber as a phishing website."} 
        imgSrc={"./salmon-demo.png"} projectLink={"https://github.com/joananeas/salmon"}/>
      </div>

      <div className="App-frame-projects dark" id="skillsDiv">
        <h2>Skills</h2>
        <txt>This is what I can "confidently" call as some of my skills. As I've been working with tons of hours :)</txt>
        <Skill skillName={"PHP"}/>
        <Skill skillName={"JavaScript"}/>
        <Skill skillName={"C++"}/>
      </div>

      {/* <div className="App-frame light" id="contactDiv">
        <h2>Contact</h2>
        <p>Here are some of my contacts</p>
      </div> */}

      <footer>
        <p>Made with ❤️ by Joan Aneas. <a href='https://github.com/sponsors/joananeas'><i>Consider supporting me!</i></a></p>
      </footer>

    </div>
  );
}

function Project({ title, description, imgSrc, projectLink}) {
  return (
    <div className="App-project">
      <h3>{title}</h3>
      <p>{description}</p>
      <img src={imgSrc} alt={`project ("${title}") screenshot`}/>
      <br/>
      <a href={projectLink}><button>View Project</button></a>
    </div>
  );
}

function Skill({ skillName }) {
  return (
    <div className="App-skill">
      {skillName}
    </div>
  );
}
export default App;
